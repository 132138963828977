export const TOKEN_STORAGE_KEY = 'auth';
export const CURRENT_SHOP_STORAGE_KEY = 'shop_storage_key';
export const CURRENT_INVITATION_CODE = 'current_invitation_code';
export const CURRENT_LANGUAGE = 'current_language';
export const CURRENT_CART_FILTER = 'current_cart_filter';
export const CURRENT_ORDER_FILTER = 'current_order_filter';
export const SALE_REPORT_DOWNLOADED = 'sale_report_downloaded';
export const SHOULD_SHOW_DELIVERY_ONBOARDING = 'should_show_delivery_onboarding';
export const SAVED_CART_IDS = 'saved_cart_ids';
export const SAVED_ORDER_IDS = 'saved_order_ids';
export const IG_LIVE_TUTORIAL_STEP = 'ig_live_tutorial_step';
export const PROFILE_ID = 'profile_id';
export const BUSINESS_PROFILE_ID = 'business_profile_id';
export const PREPARE_LOGIN = 'prepare_login';
export const AGREED_TO_FB_NOTIFICATION_POLICIES = 'agreed_to_fb_notification_policies';
export const BUILD_COUNTRY_LOCAL_KEY = 'build_country';
export const MANUALLY_ADDED = 'manuallyAdded';
export const TEMP_ID = 'tempId';
export const SEEN_NEW_PRODUCT_TEMPLATE_WALK_THROUGH = 'seen_new_product_template_walk_through';
export const NEXT_REINDEX_PRODUCTS_TIME = 'next_reindex_products_time';
export const NEXT_REINDEX_ORDERS_TIME = 'next_reindex_orders_time';
export const UNDERSTOOD_PRODUCT_STOCK_CHANGE = 'understood_product_stock_change';
export const SEEN_BUNDLE_DEAL_BANNER = 'seen_bundle_deal_banner';
export const CURRENT_BUNDLE_STATUS_FILTER = 'current_bundle_status_filter';
export const BUNDLE_DEALS_SURVEY_CLICKED_CTA_BUTTON_SUFFIX = 'bundle_deals_survey_clicked_cta_button';
export const CLOSED_MULTI_BUNDLE_INTRODUCTION_BANNER = 'closed_multi_bundle_introduction_banner';
export const CLOSED_PERCENTAGE_OFF_BUNDLE_INTRODUCTION_BANNER = 'closed_percentage_off_bundle_introduction_banner';
export const NOTIFIED_NEW_UPDATES = 'notified_new_updates';
export const SHOW_HIDE_COLUMNS = 'show_hide_columns';
export const CREATE_UPDATE_PRODUCT = 'create_update_product';
export const CREATE_NEW_PRODUCT_CREATE_CART = 'create_new_product_create_cart';
export const CREATE_NEW_PRODUCT_LIVE_SELLING = 'create_new_product_live_selling';
export const PENDING_ORDER = 'pending_order_v2';
export const PAID_ORDER = 'paid_order';
export const LOGIN_CONFIRMATION_REDIRECT = 'login_confirmation_redirect';
export const QUICK_CREATE_PRODUCT = 'quick_create_product';
export const PRODUCT_MANAGEMENT = 'product_management';
export const LIVE_SESSION_ALERTED = 'live_session_alerted';
export const PIN_CODE = 'pin_code';
export const EXPAND_PERFORMANCE_SECTION = 'expand_performance_section';
export const LIVE_SELLING_APPLY_ON = 'live_selling_apply_on';
export const CURRENT_ANALYTICS_FILTER = 'current_analytics_filter';
export const CURRENT_LIVE_SESSION_FILTER = 'current_live_session_filter';
export const PAGE_TOKEN_EXPIRED_MODAL = 'page_token_expired_modal';
export const FEATURE_GUIDE = 'feature_guide';
export const CART_DETAIL_GUIDE = 'cart_detail_guide';
export const BUNDLE_FREE_GIFT_KEY = 'BUNDLE_FREE_GIFT';
export const ITEMS_SOLD = 'items_sold';
export const CURRENT_ITEMS_SOLD_FILTER = 'current_items_sold_filter';
export const ITEMS_SOLD_EXPORT_SUMMARY_FORM = 'items_sold_export_summary_form';
export const ITEMS_SOLD_EXPORT_DETAILS_FORM = 'items_sold_export_details_form';

export const SIDER_WIDTH = 200;
export const COLLAPSED_SIDER_WIDTH = 64;

export const isDev = process.env.REACT_APP_ENV === 'dev';
export const isProd = process.env.REACT_APP_ENV === 'prod';
export const isStg = process.env.REACT_APP_ENV === 'stg';

export const APP_REGION = !isDev
  ? process.env.REACT_APP_REGION
  : localStorage.getItem(BUILD_COUNTRY_LOCAL_KEY) || process.env.REACT_APP_REGION;

export const isSG = APP_REGION === 'sg';
export const isPH = APP_REGION === 'ph';
export const isMY = APP_REGION === 'my';
export const isTH = APP_REGION === 'th';
export const isVN = APP_REGION === 'vn';

export const PAYMONGO_SIGN_UP_URL = 'https://dashboard.paymongo.com/signup?ap=sme&affiliate_partner=upmesh';

export const IMPORT_PRODUCT_CSV_TEMPLATE = isTH
  ? 'https://upmesh-public-file-store.s3.ap-southeast-1.amazonaws.com/dev/Mass-Create+Product+template_Thai+v2023.0531.csv'
  : 'https://upmesh-public-file-store.s3.ap-southeast-1.amazonaws.com/dev/Mass-Create+Product+template+v2023.0531.csv';

export const IMPORT_PRODUCT_EXCEL_TEMPLATE = isVN
  ? 'https://upmeshuat-template.s3.ap-southeast-1.amazonaws.com/public/mass-create-product-vn.xlsx'
  : 'https://upmeshuat-template.s3.ap-southeast-1.amazonaws.com/public/mass-create-product.xlsx';

export const IMPORT_PRODUCT_EXCEL_TEMPLATE_OLD = isTH
  ? 'https://upmesh-public-file-store.s3.ap-southeast-1.amazonaws.com/dev/Mass_Create_Product_Template_Thai+v2023.0531.xlsx'
  : 'https://upmesh-public-file-store.s3.ap-southeast-1.amazonaws.com/dev/Mass-Create+Product+template+v2023.0531.xlsx';

export const UPDATE_PRODUCT_CSV_TEMPLATE = isTH
  ? 'https://upmesh-public-file-store.s3.ap-southeast-1.amazonaws.com/dev/Mass-Update+Product+template_Thai+v2023.0531.csv'
  : 'https://upmesh-public-file-store.s3.ap-southeast-1.amazonaws.com/dev/Mass-Update+Product+template+v2023.0531.csv';

export const UPDATE_PRODUCT_EXCEL_TEMPLATE = isTH
  ? 'https://upmesh-public-file-store.s3.ap-southeast-1.amazonaws.com/dev/Mass_Update_Product_Template_Thai+v2023.0531.xlsx'
  : 'https://upmesh-public-file-store.s3.ap-southeast-1.amazonaws.com/dev/Mass-Update+Product+template+v2023.0531.xlsx';

export const BUNDLE_DEAL_SURVEY_LINK = 'https://sjoak6qei21.typeform.com/to/WKCmecxV';

export const IMPORT_PARTICIPANT_TEMPLATE =
  'https://upmesh-public-file-store.s3.ap-southeast-1.amazonaws.com/lucky-draw/Lucky+draw+-+participant+list.xlsx';

// for AB-Testing (Feature flag)
export const PAYMENT_GATEWAY_V3 = 'PaymentGatewayV3';
export const PAYMENT_GATEWAY = 'PaymentGateway';
export const SLIP_VERIFICATION = 'SlipVerification';
export const LOGISTICv1 = 'LOGISTICv1';
export const NO_PRODUCT_BRANCHING = 'NoProductBranching';
export const SOCIAL_CHAT = 'SocialChat';
export const FILLUP_WEBSITE = 'FillUpWebsite';
export const OFFLINE_PAYMENT = 'PaymentMethodOffline_UP12726';

export const TIMEOUT_UPLOAD = 10 * 60 * 1000;
export const MAX_PRODUCT_STOCK = 1000000000;

export const MAX_WINNER = 6;
export const DRAW_TIME = 20000;
export const DRAW_COUNT = 10;

export const WARNING_PRODUCT_NAME_LENGTH = 50;
export const MAX_PRODUCT_NAME_LENGTH = 250;
export const MAX_PRODUCT_DESCRIPTION_LENGTH = 2000;
export const MAX_PRODUCT_CODE_LENGTH = 20;
export const MIN_PRODUCT_CODE_LENGTH = 2;
export const MAX_PRODUCT_SKU_LENGTH = 50;
export const MAX_ATTRIBUTE_TEXT_LENGTH = 15;
export const MAX_OPTION_TEXT_LENGTH = 35;
export const MAX_PROMO_NAME_LENGTH = 50;
export const MAX_WIDTH = 1100;
export const MAX_PRODUCT = 1000;
export const MAX_PRODUCT_FETCH_BY_ES = 10000;
export const MAX_LUCKYDRAW_COMMENT_LENGTH = 12;
export const MIN_LUCKYDRAW_COMMENT_LENGTH = 3;

export const MASTER_PRODUCT_GROUP_NAME = 'Master Product Group';
export const IS_HIDE_CITY_STATE_FOR_SG = true;

export const searchOrderOptions = [
  {
    value: 'all',
    label: 'all',
  },
  {
    value: 'cartIds',
    label: 'cartid',
  },
  {
    value: 'facebook',
    label: 'facebook_user_name',
  },
  {
    value: 'instagram',
    label: 'instagram_user_name',
  },
  {
    value: 'buyerApp',
    label: 'buyer_app_account_name',
  },
  {
    value: 'recipient',
    label: 'recipient_name',
  },
  {
    value: 'phoneNumber',
    label: 'phone_number',
  },
  {
    value: 'postalCode',
    label: 'postal_code',
  },
];

// according to config from BE
export const OTHER_CATEGORY_OPTION = 0;

export const IMPORT_PROMOCODE_EXCEL_TEMPLATE =
  'https://upmesh-public-file-store.s3.ap-southeast-1.amazonaws.com/dev/Mass-Create+Promo+Code+template+v2023.0820.xlsx';

export const QUOTA_DOWNLOAD = 1000;

export const DELIVERY_NAME_MIN_LENGTH = 5;
export const DELIVERY_NAME_MAX_LENGTH = 25;
export const DELIVERY_ADDITIONAL_INFOR_MAX_LENGTH = 30;

export const DELIVERY_GROUP_NAME_MAX_LENGTH = 25;
export const DELIVERY_GROUP_NOTE_MAX_LENGTH = 30;

export const DEFAULT_PAGE_SIZE = 10;

export const CHECKOUT_MSG_MAX_LENGTH_AFTER_UPDATED = 345;
export const CHECKOUT_MSG_MAX_LENGTH_BEFORE_UPDATE = 1500;

export const HELP_CENTER_LINK = 'https://www.upmesh.io/help-centre-english';
export const BUNDLE_DEAL_HELP_CENTER = 'https://www.upmesh.io/help/bundle-deals';
export const PROMOTION_HELP_CENTER = 'https://www.upmesh.io/help/promo-code';
export const LIVE_SELLING_HELP_CENTER = 'https://www.upmesh.io/help/live-selling';
export const PRODUCT_HELP_CENTER = 'https://www.upmesh.io/help-category/products-inventory';
export const PAYMENT_SETTING_HELP_CENTER = 'https://www.upmesh.io/help/set-up-payment-methods';
export const MASS_CREATE_PRODUCT_HELP_CENTER = 'https://www.upmesh.io/help/mass-create-product';
export const PAGE_TOKEN_EXPIRED_HELP_CENTER = 'https://www.upmesh.io/help/error-pages-token-has-expired';

export const REFRESH_PRODUCT_INTERVAL = 30000;
export const SUBSCRIPTION_ALERT_RANGE = 30;
export const DELIVERY_INSTRUCTION_MAX_LENGTH = 80;
export const INTERNAL_NOTE_MAX_LENGTH = 100;

export const APPSYNC_CONFIGS = {
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_APPSYNC_ENDPOINT,
  aws_appsync_region: process.env.REACT_APP_APPSYNC_REGION,
  aws_appsync_authenticationType: 'API_KEY',
  aws_appsync_apiKey: process.env.REACT_APP_APPSYNC_APIKEY,
};
export const APPSYNC_PINGPONG_INTERVAL = 30000;

export const VIDEO_LINK_LIVE_SELLING = isVN
  ? 'https://upmesh-videos.s3.ap-southeast-1.amazonaws.com/vn_liveselling.mp4'
  : 'https://upmesh-videos.s3.ap-southeast-1.amazonaws.com/liveseling.mp4';

export const SUBSCRIPTION_PASTDUE_DAYS = 14;
export const HIDDEN_PERMISSIONS = ['CrawlerService', 'SubscriptionFeature'];
export const SUBCRIPTION_TRIAL_DAYS = 30;
export const SUBCRIPTION_CANCEL_DAYS = 7;

export const BUNDLE_PRICE_TYPE_MIN_QUANTITY = Number.MIN_SAFE_INTEGER;
export const BUNDLE_PRICE_TYPE_MAX_QUANTITY = Number.MAX_SAFE_INTEGER;
export const BUNDLE_PERCENTAGE_OFF_TYPE_MIN_QUANTITY = 1;
export const BUNDLE_PERCENTAGE_OFF_TYPE_MAX_QUANTITY = 100;
export const BUNDLE_MAX_PRODUCT_NUMBER = 300;
export const MY_SUBSCRIPTION_TRIAL_WARN = 21;

export const MY_PRICING_PAGE = 'https://www.upmesh.io/pricing-my';

export const VIDEO_STOCK_RESERVATION = 'https://upmesh-videos.s3.ap-southeast-1.amazonaws.com/sold+list.mp4';
export const STOCK_RESERVATION_PAGE = 'https://www.upmesh.io/help/view-bidders-list';
export const NEGATIVE_PRICE_PAGE = 'https://www.upmesh.io/help/negative-product-price';
export const CART_DETAIL_PAGE = 'https://www.upmesh.io/help/edit-carts';
export const CART_DETAIL_VIDEO = 'https://upmesh-videos.s3.ap-southeast-1.amazonaws.com/Orders.mp4';
export const FREE_GIFT_PAGE = 'https://www.upmesh.io/help/free-gift-in-bundle-deals';
export const BLACK_LIST_PAGE = 'https://www.upmesh.io/help/blacklist';
export const LUCKY_DRAW_PAGE = 'https://www.upmesh.io/help/lucky-draw';
export const POST_MANAGEMENT_PAGE = 'https://www.upmesh.io/help/live-selling';

export const MAX_NUMBER_UPLOADED_IMAGES = 5;

export const INVOICE_NOTE_MAX_LENGTH = 200;
export const INVOICE_NOTE_CHARACTER_PER_LINE = 40;
export const INVOICE_NOTE_MAX_ROWS = 5;

export const REF_ORDER_PREFIX_MAX_LENGTH = 3;
export const INVOICE_TITLE_MAX_LENGTH = 12;
export const SHOP_INFO_MAX_LENGTH = 60;
export const UEN_NUMBER_MAX_LENGTH = 10;
export const DELAY_TIME_EXPORT = 2000; // in ms
